.alf-panel {
  width: 100%;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.alf-panel:not(:first-child)::before {
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  content: '';
  opacity: 1;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.12);
}

.alf-panel.onFocus {
  box-shadow: 0px 0px 6px 1px #483499 !important;
  outline: none;
  margin: 3px 0 3px 0;
  padding: 0 3px 0 3px;
}

.alf-panel > .summary {
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  padding: 0 24px 0 24px;
  min-height: 48px;
  cursor: pointer;
  transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.alf-panel.expanded > .summary {
  min-height: 64px;
}

.alf-panel > .summary > .content {
  margin: 12px 0;
  flex-grow: 1;
  font-size: 0.9375px;
  font-size: min(0.9375rem, calc(0.9375px * 1.5));
  font-weight: 400;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.alf-panel.expanded > .summary > .content {
  margin: 20px 0;
}
.alf-panel > .summary > .expand-icon {
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  margin-right: -12px;
  flex: 0 0 auto;
  padding: 12px;
  overflow: visible;
  font-size: 1.5px;
  font-size: min(1.5rem, calc(1.5px * 1.5));
  text-align: center;
  border-radius: 50%;
}

.alf-panel-button {
  background-color: #fff !important;
  color: #6648dc !important;
  border: 3px solid #6648dc !important;
}

.alf-panel-button:focus {
  background-color: #483499 !important;
  border: 3px solid #483499 !important;
  color: #fff !important;
  text-decoration: underline;
  outline: 0;
}

.alf-panel .details {
  padding: 0px 24px 24px;
}

@media only screen and (max-width: 768px) {
  .alf-panel > .summary {
    display: block;
    padding-right: 0px;
  }
}
