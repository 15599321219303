.make_payment__button {
  margin: 10px;
}
.make_payment__button:hover,
.make_payment__button:focus {
  cursor: pointer;
  background: #483499 !important;
  color: #fff !important;
  box-shadow: 0px 0px 6px 1px #6648dc;
  outline: none;
  border: 3px solid #483499 !important;
}

.make_payment__logo {
  margin-bottom: 30px;
  margin-top: 20px;
}

.make_payment__text {
  font-size: 16px !important;
}

.make_payment__child {
  text-align: center;
}

/* Small screen */
@media (min-width: 0px) { 
  .make_payment__child {
    margin: 0px ;
  }
}

/* Medium screen */
@media (min-width: 700px) { 
  .make_payment__child {
    margin:25px 20px 20px ;
  }
}