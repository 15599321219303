html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  -moz-osx-font-smoothing: grayscale;
  font-size: 6.25%;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
@font-face {
  font-family: 'Mulish';
  font-weight: 400;
  src: url('./assets/fonts/Mulish.ttf');
}
@font-face {
  font-family: 'MulishSemiBold';
  font-weight: 900;
  src: url('./assets/fonts/Mulish-SemiBold.ttf');
}
@font-face {
  font-family: 'MulishBold';
  font-weight: 900;
  src: url('./assets/fonts/Mulish-Bold.ttf');
}
body {
  margin: 0;
  font-size: 16px;
  font-size: min(16rem, calc(16px * 1.5));
  font-family: 'Mulish';
}
@media print {
  body {
    background-color: #fff;
  }
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input {
  border-radius: 0;
  -webkit-appearance: none;
}
