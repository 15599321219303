.alf-policy-row {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-bottom: 10px;
}

.alf-policy-row:first-child {
  margin-left: 0;
}

.alf-policy-row .v-line {
  display: none;
}
.alf-policy-row.keep-vertical {
  margin-left: 0;
  margin-right: 16px;
  margin-bottom: 10px;
  width: 100%;
}

.alf-policy-row.keep-vertical > span {
  display: block;
  margin: 0;
}

@media only screen and (min-width: 500px) {
  .alf-policy-row {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  br.hidden-lg {
    display: none;
  }

  .alf-policy-row .v-line {
    display: block;
    border-left: 1px solid #5b564d;
    height: 12px;
    display: inline-block;
    margin-left: 16px;
  }

  .alf-policy-row {
    display: inline-block;
    margin-left: 16px;
  }

  .alf-policy-row.vertical {
    margin-left: 0;
    margin-right: 16px;
    margin-bottom: 10px;
    width: 100%;
  }

  .alf-policy-row.vertical > span {
    display: block;
    margin: 0;
  }
}
