.label {
  border-radius: 0.2em;
  padding: 0.12em 0.4em 0.14em;
  margin: 0 0.42em;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  font-size: min(16rem, calc(16px * 1.5));
  font-family: 'proxima-nova', 'Helvetica', 'Arial', 'sans-serif';
  display: inline;
  vertical-align: baseline;
  vertical-align: middle;
  background-color: #595959;
}
.label.color-blue {
  background-color: #6f9ad1;
}
.label.color-red {
  background-color: #dc2a2a;
}
.label.color-green {
  background-color: #10b981;
}
.label.color-orange {
  background-color: #dea154;
}
.label.color-yellow {
  background-color: #b8860d;
}
.label.color-purple {
  background-color: #9f83d1;
}
