.alf-status {
  padding: 2px 20px;
  border: 0;
  border-radius: 23px;
  font-family: 'proxima-nova', 'Helvetica', 'Arial', 'sans-serif';
  font-size: 13px;
  font-size: min(13rem, calc(13px * 1.5));
  display: inline-block;
  height: max-content;
}

.alf-status.Open {
  background-color: #e8e4fa;
  color: #6648dc;
  border: 3px solid #e8e4fa;
}

.alf-status.Closed {
  background-color: #fff;
  color: #6648dc;
  border: 3px solid #e8e4fa;
}
