.alf-select {
  box-sizing: border-box;
  margin: 0;
  position: relative;
  display: inline-block;
  height: 36px;
  padding: 8px 18px 8px 8px;
  font-size: 16px;
  font-size: min(16rem, calc(16px * 1.5));
  border: 1px solid #5b564d;
  transition: all 0.3s;
  background-color: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.alf-select.no-focus:focus {
  border: 1px solid #5b564d;
  outline: none;
  box-shadow: none;
}
.alf-select:focus {
  border-color: #5d79bd;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.alf-select.no-focus:focus {
  border: 1px solid #5b564d;
  outline: none;
  box-shadow: none;
}

.alf-select > .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}
.alf-select > .header[disabled],
.alf-select > .header[disabled]:hover {
  cursor: not-allowed;
  color: #333333;
  background-color: #dbdbdb;
  text-shadow: none;
  box-shadow: none;
  border-color: #dbdbdb;
}
.alf-select > .header > .title {
  display: inline-block;
  font: 400 16px Arial;
  border: none;
  cursor: pointer;
  pointer-events: none;
  background-color: transparent;
  width: -moz-available;
}
.alf-select > .header > .placeholder {
  color: #acaaa5;
  font-size: 16px;
  font-size: min(16rem, calc(16px * 1.5));
  display: inline-block;
  border: none;
  cursor: pointer;
  pointer-events: none;
  background-color: transparent;
}
.alf-select > .header > .title:focus {
  outline: none;
}
.alf-select > .header > .placeholder:focus {
  outline: none;
}
.alf-select.fullwidth {
  width: 100%;
}
.popover {
  margin-top: 2px;
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #5b564d;
  z-index: 9999;
  overflow-y: auto;
  width: 100%;
}
.popover .row {
  width: 100%;
  display: inline-block;
  padding: 10px 16px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.popover .row.selected {
  background-color: #d3e7e6;
}
.popover .row:focus {
  background-color: #59b7b3;
  outline: none;
}
.popover .row:hover {
  background-color: #59b7b3;
}
.tooltip-body {
  background-color: #fff;
  border: 1px solid #dadde9;
  border-radius: 4px;
  transform: scale(1, 1) translateZ(0px);
  max-width: 400px;
}
.alf-select.input-group {
  border-right: none;
}
.alf-select.error {
  border: 1px solid #dc2a2a;
}
.alf-select.error:focus {
  border-color: #dc2a2a;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.alf-select.error.no-focus:focus {
  border: 1px solid #dc2a2a;
  outline: none;
  box-shadow: none;
}
.alf-select.success {
  border: 1px solid #1e824c;
}
.alf-select.success:focus {
  border-color: #1e824c;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px #8fd8ae;
}
.alf-select.success.no-focus:focus {
  border: 1px solid #1e824c;
  outline: none;
  box-shadow: none;
}
.alf-select[disabled],
.alf-select[disabled]:hover {
  cursor: not-allowed;
  color: #333333;
  background-color: #dbdbdb;
  text-shadow: none;
  box-shadow: none;
  border-color: #dbdbdb;
}
