.alf-typography {
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5;
  margin: 0;
  padding: 0;
  font-family: 'Mulish';
  word-break: break-word;
}
.alf-typography.word-nowrap {
  word-break: unset;
}
.alf-typography:focus {
  outline: none;
}
.alf-typography.with-tab:focus {
  outline: none;
  text-decoration: underline;
}
.alf-typography.welcome {
  text-align: center;
  color: #483499;
  font-size: 48px;
  font-size: min(48rem, calc(48px * 1.5));
  font-weight: 800;
  line-height: 20px;
}

.alf-typography.heading1 {
  color: #6648dc;
  font-size: 54px;
  font-size: min(54rem, calc(54px * 1.5));
  font-weight: 600;
  line-height: 1.23;
}

.alf-typography.heading2 {
  color: #6647dc;
  font-family: 'MulishBold';
  font-size: 36px;
  font-size: min(36rem, calc(36px * 1.5));
  font-weight: 900;
  line-height: 1.35;
}

.alf-typography.heading3 {
  color: #483499;
  font-family: 'MulishBold';
  font-size: 24px;
  font-size: min(24.5rem, calc(24px * 1.5));
  font-weight: 900;
  line-height: 1.35;
  margin-bottom: 8px;
}
.alf-typography.menuOption {
  color: white;
  font-size: 24px;
  font-size: min(24rem, calc(24px * 1.5));
  font-weight: 600;
  line-height: 1.35;
  margin: 'auto 0px';
}
.alf-typography.menuTitle {
  color: white;
  font-size: 38px;
  font-size: min(38rem, calc(38px * 1.5));
  font-weight: 600;
  line-height: 1.35;
  margin-bottom: 74px;
}
.alf-typography.subtitle {
  color: #5b564d;
  font-size: 36px;
  font-size: min(36rem, calc(36px * 1.5));
}
.alf-typography.subtitle2 {
  color: #6647dc;
  font-weight: 800;
  font-size: 16px;
  font-size: min(16rem, calc(16px * 1.5));
}
.alf-typography.subtitle3 {
  color: #333333;
  font-weight: 600;
  font-size: 38px;
  font-size: min(38rem, calc(38px * 1.5));
}
.alf-typography.paragraph {
  color: #333333;
  font-size: 16px;
  font-size: min(16rem, calc(16px * 1.5));
  line-height: 20px;
  font-weight: 500;
}
.alf-typography.error {
  color: #dc2a2a;
  font-size: 14px;
  font-size: min(14rem, calc(14px * 1.5));
}
.alf-typography.caption1 {
  color: #5b564d;
  font-size: 16px;
  font-size: min(16rem, calc(16px * 1.5));
}
.alf-typography.caption2 {
  color: #000000;
  font-size: 16px;
  font-size: min(16rem, calc(16px * 1.5));
}
.alf-typography.caption3 {
  color: #6648dc;
  font-size: 16px;
  font-size: min(16rem, calc(16px * 1.5));
  font-weight: 600;
}
.alf-typography.caption4 {
  color: #6647dc;
  font-size: 19px;
  font-size: min(19rem, calc(19px * 1.5));
  font-weight: 600;
}
.alf-typography.caption5 {
  color: #6648dc;
  font-size: 14px;
  font-size: min(14rem, calc(14px * 1.5));
  font-style: italic;
}
.alf-typography.linkButton {
  color: #6647dc;
  font-size: 18px;
  font-size: min(18rem, calc(18px * 1.5));
  font-weight: 800;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

@media (max-width: 1370px) {
  .alf-typography.welcome {
    font-size: 52px;
    font-size: min(52rem, calc(52px * 1.5));
  }
}
@media (max-width: 768px) {
  .alf-typography.welcome {
    font-size: 45px;
    font-size: min(45rem, calc(45px * 1.5));
  }
}
@media (max-width: 425px) {
  .alf-typography.welcome {
    font-size: 36px;
    font-size: min(36rem, calc(36px * 1.5));
  }
  .alf-typography.subtitle {
    color: #5b564d;
    font-size: 18px;
    font-size: min(18rem, calc(18px * 1.5));
  }

  .alf-typography.menuOption {
    color: white;
    font-size: 18px;
    font-size: min(18rem, calc(18px * 1.5));
    font-weight: 600;
    line-height: 1.35;
    margin: 'auto 0px';
  }
  .alf-typography.menuTitle {
    color: white;
    font-size: 32px;
    font-size: min(32rem, calc(32px * 1.5));
    font-weight: 600;
    line-height: 1.35;
    margin-bottom: 64px;
  }
}
