.warning__paragraph {
  margin-bottom: 20px;
  text-align: center;
  font-size: 13px;
}

.warning__content {
  margin: 0px 32px;
}

.warning__button {
  width: 115px;
}

.warning__warning_div {
  text-align: center;
  margin-bottom: 10px;
  background-color: #F5F7F9;
  padding: 15px 5px 15px 5px;
}

.warning__warning_title {
  font-size: 13px;
  margin-bottom: 10px;
}

.warning__div_warning_content {
  font-size: 13px;
}