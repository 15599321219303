.alf-footer {
  width: 100%;
  padding: 33px 44px 33px 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f9fafb;
  justify-content: space-between;
}
.alf-footer .text {
  display: inline-flex;
}

.alf-footer .divider {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.alf-footer .title {
  color: #6647dc;
  text-decoration: none;
  font-size: 22px;
  font-size: min(22rem, calc(22px * 1.5));
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 26px;
  font-family: 'Mulish';
  word-break: break-word;
}

.alf-footer .phone {
  color: #6647dc;
  text-decoration: none;
  font-family: 'MulishBold';
  font-size: 22px;
  font-size: min(22rem, calc(22px * 1.5));
  font-weight: 900;
}

.alf-footer .link {
  color: #fff;
  margin-top: 16px;
  margin-right: 36px;
  text-decoration: none;
  font-size: 18px;
  font-size: min(18rem, calc(18px * 1.5));
}

.alf-footer .logo {
  display: block;
  float: right;
  text-align: right;
}

.alf-footer .copy {
  text-align: left;
  color: #6647dc;
  font-size: 9px;
  font-size: min(9rem, calc(9px * 1.5));
  font-weight: 700;
}
.alf-footer .button {
  font-size: min(16rem, calc(16px * 1.5));
  height: 32px;
  margin-right: 20px;
  margin-top: 14px;
}
.privacy {
  text-decoration: unset;
  color: rgb(255, 255, 255);
  font-size: 28px;
  font-size: min(28rem, calc(28px * 1.5));
  font-weight: 600;
}
.alf-footer .privacy:hover {
  text-decoration: underline;
}
.alf-footer .message {
  margin-top: 26px;
  max-width: 969px;
  color: #6647dc;
  text-decoration: none;
  font-size: 14px;
  font-size: min(14rem, calc(14px * 1.5));
  font-weight: 700;
  margin-right: 20px;
  line-height: 20px;
  margin-bottom: 8px;
  font-family: 'Mulish';
  word-break: break-word;
}
.alf-footer .buttons-div {
  display: inherit;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

@media (max-width: 960px) {
  .alf-footer .text {
    display: block;
  }
}

@media (max-width: 767px) {
  .alf-footer .title {
    margin-bottom: 10px;
  }
  .alf-footer .divider {
    text-align: center;
  }
  .alf-footer .logo {
    display: block;
    float: none;
    text-align: center;
  }
  .alf-footer {
    display: block;
  }
  .alf-footer .copy {
    text-align: center;
  }
  .alf-footer .buttons-div {
    display: flex;
    align-items: center;
    flex-direction: column;
    list-style: none;
  }
  .alf-footer .button {
    margin-right: 0px !important;
  }
  .alf-footer .message {
    margin-right: 0px;
  }
}
