/* Small Screen */
@media (min-width: 0px) {
  .maintenanceLogo .logo {
    width: 80px;
    margin: 3%;
  }
  .maintenanceImg {
    width: 271px;
    margin: 5%;
  }
  .maintenancePage .panelLeft {
    text-align: center;
  }
  .maintenancePage .rightItems {
    width: 360px;
  }
  .circleIcon .icon {
    width: 50%;
  }
  .maintenancePage .circleIcon {
    text-align: center;
  }
  .maintenancePage .item-subtitle {
    font-size: 18px !important;
    margin-bottom: 0px !important;
  }
  .maintenancePage .item-paragraph {
    font-size: 11px !important;
    line-height: 1.5 !important;
  }
  .maintenancePage .customerService {
    margin-top: 5%;
  }
}

/* Medium Screen */
@media (min-width: 700px) {
  .maintenancePage .rightItems {
    width: 700px;
  }
  .maintenanceImg {
    width: 300px;
  }
  .maintenancePage .panelRight {
    height: 140px;
  }
  .circleIcon .icon {
    width: auto;
  }
  .maintenancePage .item-subtitle {
    font-size: 24px !important;
  }
  .maintenancePage .item-paragraph {
    font-size: 16px !important;
    line-height: 20px !important;
  }
}

/* Large Screen */
@media (min-width: 1280px) {
  .maintenanceLogo {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .maintenancePage .panelLeft {
    text-align: end !important;
  }
  .maintenancePage .panelRight {
    height: 440px;
  }
  .maintenanceLogo .logo {
    width: 280px;
    margin-bottom: 0px;
  }
  .maintenanceImg {
    width: 471px;
    margin: 0px;
  }
  .maintenancePage {
    margin: 2%;
  }
  .circleIcon .icon {
    margin-top: 10px;
  }
  .maintenancePage .rightItems {
    width: 524px;
  }
  .maintenancePage .circleIcon {
    text-align: left;
  }
  .maintenancePage .customerService {
    margin-top: 56px;
  }
}
