.alf-checkbox .wrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
}

.alf-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.alf-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 4px;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #000;
}

.alf-checkbox input:focus {
  cursor: pointer;
  box-shadow: 10px 7px 8px 10px #6648dc;
  outline: none;
  opacity: unset;
}

.alf-checkbox input:checked ~ .checkmark {
  background-color: #6648dc;
}

.alf-checkbox .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.alf-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.alf-checkbox .checkmark:after {
  left: 6px;
  top: 0px;
  width: 8px;
  height: 16px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.alf-checkbox.disabled .wrapper {
  cursor: not-allowed;
  user-select: none;
  color: rgba(0, 0, 0, 0.38);
}
.alf-checkbox.disabled .checkmark {
  background-color: rgba(0, 0, 0, 0.26);
  border: none;
}
