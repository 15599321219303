.success__paragraph {
  margin-bottom: 10px;
}
.success__content {
  margin-right: 20px;
  margin-left: 20px;
}
.success__button {
  width: 185px;
}
.success__icon {
  text-align: center;
  margin-bottom: 10px;
}
.success__title {
  margin-bottom: 20px;
  text-align: center;
}
.success__caption5 {
  margin-top: 20px;
  font-size: 16px !important;
  font-weight: 600;
  padding: 0px 10px;
}