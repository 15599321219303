.alf-sidebar {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  width: 80%;
  background-color: #2e2a3a;
  padding: 76px 36px;
  color: #fff;
  max-width: 378px;
  height: 100%;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 425px) {
  .alf-sidebar {
    padding: 54px 28px;
    max-width: 250px;
  }
}
.alf-sidebar > .wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}
.alf-background {
  width: 100%;
  z-index: 100;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000080;
  transition: all 0.3s;
  visibility: visible;
  opacity: 1;
}
.alf-sidebar > .wrapper > .option {
  display: flex;
  align-items: center;
  margin: 16px 0px;
}
