.alf-app-bar {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #c0c0c0;
  position: relative;
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.alf-app-bar::after {
  background: linear-gradient(
    to right,
    #6648dc 0px,
    #6648dc 80px,
    #483499 80px,
    #483499 160px,
    #cdc5ec 160px,
    #cdc5ec 240px,
    transparent 240px,
    transparent 100%
  );
  position: absolute;
  content: '';
  height: 5px;
  right: 0;
  left: 0;
  bottom: -3px;
}
.alf-app-bar > nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.alf-app-bar nav > .left {
  display: flex;
  align-items: center;
}

.alf-app-bar nav > .logo {
  width: 182px;
  cursor: pointer;
  user-select: none;
}
.alf-app-bar nav > .left > a > .logo {
  width: 182px;
  cursor: pointer;
  user-select: none;
}

.alf-app-bar nav > .right > div {
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px;
}

.alf-app-bar nav .logout {
  user-select: none;
  cursor: pointer;
  font-size: 18px;
  font-size: min(18rem, calc(18px * 1.5));
}

@media (min-width: 1280px) {
  .alf-app-bar {
    padding: 30px 54px;
  }
}
