.no-policies__heading {
  font-size: 1px;
  font-size: min(1rem, calc(1px * 1.5)) !important;
  text-align: center;
  margin-bottom: 10px;
}

.no-policies__help {
  font-size: 0.9px;
  font-size: min(0.9rem, calc(0.9px * 1.5)) !important;
}

.no-policies__link {
  width: 100%;
  margin: 20px 0;
}

@media (min-width: 576px) {
  .no-policies__card {
    margin-top: 30px;
  }
}

@media (min-width: 768px) {
  .no-policies__heading {
    font-size: 24px;
    font-size: min(24rem, calc(24px * 1.5)) !important;
    margin-top: 20px;
  }

  .no-policies__link {
    font-size: 20px;
    font-size: min(20rem, calc(20px * 1.5));
    margin: 30px 0;
  }

  .no-policies__help {
    font-size: 18px;
    font-size: min(18rem, calc(18px * 1.5)) !important;
  }

  .no-policies__card {
    max-width: 550px;
  }
}
