.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
  display: block;
}

.react-switch-label {
  width: 44px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
  background: #B4BCCE;
}

.react-switch-div {
  width: min-content;
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  height: 1.5rem;
}

.react-switch-span {
  color: rgb(0 0 0);
  font-weight: 700;
  margin-top: auto;
  margin-bottom: auto;
}

.react-switch-button {
  width: 20px;
  height: 20px;
  left: 2px;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  border-radius: 40px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  border-width: 1px;
  border-color: #B4BCCE;
}

.react-switch-checkbox:checked+.react-switch-label .react-switch-button {
  left: calc(100% + 2px);
  transform: translateX(-100%);
}

.react-switch-checkbox:checked+.react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-checkbox:checked+.react-switch-label {
  background: #6648DC;
}

.react-switch-label:active .react-switch-button {
  width: 24px;
}