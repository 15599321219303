.alf-formfield {
  line-height: 24px;
  position: relative;
  height: auto;
}

.alf-formfield.fullwidth {
  width: 100%;
}

.alf-formfield > .feedback {
  text-align: left;
  clear: both;
  min-height: 22px;
  margin-top: 6px;
  line-height: 1.5;
}

.alf-formfield > label {
  display: block;
}
