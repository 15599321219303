#landingInformational {
  text-align: center;
}
#landingInformational .list-item {
  display: flex;
  margin: 0pc 4px 10px 4px;
  align-items: center;
}

@media (min-width: 450px) {
  #landingInformational .list-item {
    font-size: 100%;
  }
}
#landingInformational .icon-title {
  color: #6647dc;
  font-size: 1em;
  white-space: nowrap;
  line-height: 20px;
}
#landingInformational .row {
  max-width: 600px;
  margin: auto;
}
#landingInformational .ul {
  margin: 0px;
  padding: 0px;
}
#landingInformational .primary-button {
  min-width: 320px;
  margin-bottom: 24px;
  border-radius: 6px;
  font-size: 16px;
}
#landingInformational .second-button {
  min-width: 320px;
  border-radius: 6px;
  font-size: 16px;
}
#landingInformational .subtitle {
  margin-top: 40px;
  margin-bottom: 20px;
  color: #6647dc;
}
#landingInformational .first-subtitle {
  margin-top: 40px;
  margin-bottom: 20px;
  color: #6647dc;
}
#landingInformational .first-paragraph {
  color: #6647dc;
}
#landingInformational .second-subtitle {
  margin-top: 60px;
  margin-bottom: 20px;
  color: #6647dc;
}
#landingInformational .mobile {
  height: 35vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#landingInformational .mobile-p {
  color: #6647dc;
  margin-bottom: 10px;
}
#landingInformational .mobile-second-p {
  color: #6647dc;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  #landingInformational .first-subtitle {
    color: #6647dc;
    padding: 0% 6%;
  }
  #landingInformational .first-paragraph {
    color: #6647dc;
    padding: 0% 6%;
  }
}
