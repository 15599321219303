.preferredEmail__content {
  margin-right: 20px;
  margin-left: 20px;
}
.preferredEmail__div {
  display: flex;
  justify-content: space-between;
}
.preferredEmail__title {
  margin-bottom: 20px;
  text-align: center;
}
.preferredEmail__span_confirm {
  background-color: #F5F7F9;
  padding: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.preferredEmail__span_paperless {
  background-color: #F5F7F9;
  padding: 15px;
  margin-top: 15px;
}

.preferredEmail__span_content {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

/* Small Screen */
@media (min-width: 0px) { 
  .preferredEmail__div {
    width: 290px;
  }
  .preferredEmail__button {
    width: 130px;
  }
}

/* Medium Screen */
@media (min-width: 700px) {
  .preferredEmail__title {
    white-space: pre-line;
  }
  .preferredEmail__div {
    width: 420px;
  }
  .preferredEmail__button {
    width: 200px;
  }
}