.tooltip-container {
  position: fixed;
  -webkit-font-smoothing: antialiased;
  box-sizing: inherit;
  z-index: 1500;
  opacity: 0.9;
  pointer-events: none;
  will-change: transform;
}

.alf-passwordcheck > .tooltip-body {
  background-color: #fff;
  padding: 10px;
  border: 1px solid #dadde9;
  pointer-events: none;
  font-size: 0.625px;
  font-size: min(0.625rem, calc(0.625px * 1.5));
  border-radius: 4px;
  transform: scale(1, 1) translateZ(0px);
  max-width: 400px;
}
.alf-passwordcheck > .tooltip-body b {
  font-weight: 600px;
}
.alf-passwordcheck .password-message {
  font-size: 14px;
  font-size: min(14rem, calc(14px * 1.5));
  margin-top: 5px;
}
.alf-passwordcheck > .tooltip-body .password-heading {
  font-size: 14px;
  font-size: min(14rem, calc(14px * 1.5));
}

.alf-progressbar {
  height: 4px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
}
.alf-progressbar .progress {
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  position: absolute;
  transform-origin: left;
  transition: transform 0.4s linear;
  z-index: 1;
}
.alf-progressbar {
  background-color: #ddd;
}
.alf-progressbar.bar-strong .progress {
  background-color: #1e824c;
}
.alf-progressbar.bar-weak .progress {
  background-color: #b8860d;
}
.alf-progressbar.bar-very-weak .progress {
  background-color: #dc2a2a;
}
.alf-passwordcheck .arrow {
  left: 0;
  margin-left: -0.95em;
  height: 3em;
  width: 1em;
  position: fixed;
  top: 43px;
}
.alf-passwordcheck .arrow::before {
  content: '';
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.5em 0.5em 0.5em 0;
  border-color: transparent #333333 transparent transparent;
}
