.alf-icon {
  width: 20px;
  height: 20px;
  transition: all 0.3s;
}
.alf-icon:focus {
  outline: none;
}
.alf-icon.button {
  user-select: none;
  cursor: pointer;
  vertical-align: middle;
}

.alf-icon.disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: transparent;
}
