.claimDetailsHeader {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}
.icon {
  margin-top: -2px !important;
}

.claimDetailsHeader .title {
  color: #000000 !important;
  margin-right: 20px;
  font-size: 20px !important;
}
.firsRow .title {
  color: #000000 !important;
  margin-right: 20px;
  font-size: 20px !important;
}

.causeloss {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
}

.location {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
}

.subtitles {
  margin-left: 7px;
  font-size: 14px !important;
}

@media only screen and (max-width: 1280px) {
  .firsRow {
    display: flex;
  }
  .secondRow {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .firsRow {
    display: flex;
    justify-content: space-between;
  }
  .subtitles {
    margin-left: 0px;
    font-size: 12px !important;
  }
  .causeloss {
    margin-right: 0px;
    padding-left: 0px !important;
  }

  .location {
    margin-right: 0px;
    margin-top: 10px;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
