.link_policy__button {
  margin: 0px 10px 10px;
  width: 170px;
}
.link_policy__button:hover,
.link_policy__button:focus {
  cursor: pointer;
  background: #483499 !important;
  color: #fff !important;
  box-shadow: 0px 0px 6px 1px #6648dc;
  outline: none;
  border: 3px solid #483499 !important;
}

.link_policy__container {
  height: '100%';
  display: 'flex';
  justify-content: 'center';
  align-items: 'center'
}

.link_policy__card {
  width: 420;
  padding: '10px 10px'
}

.link_policy__success {
  padding: 12;
  background-color: 'hsla(177; 40%; 66%; 20%)';
  font-size: 'min(14rem; calc(14px * 1.5))';
  color: '#333333';
  margin-bottom: 16
}

.link_policy__circularProgress {
  display: 'flex';
  justify-content: 'center'
}

.link_policy__errorPolicy {
  background: '#FEF7F6 0% 0% no-repeat padding-box';
  padding: 12;
  font-size: 'min(14rem; calc(14px * 1.5))';
  color: '#DC2A2A';
  margin-bottom: 16
}