.paperless__div_discount {
  display: inline-block;
}

.paperless__discountButton {
  font-size: 13px !important;
  color: #6648DC !important;
  cursor: pointer;
  text-decoration: underline;
}

.paperless__dt_switch {
  margin: 0px;
  display: flex;
  justify-content: space-between;
}

.paperless__dt_button {
  margin-top: 30px;
  text-align: center;
}

.paperless__email {
  text-align: left;
  color: #6648DC !important;
  cursor: pointer;
}

.paperless__updateEmail {
  margin-bottom: 32px;
  text-align: right;
  color: #6648DC !important;
  cursor: pointer;
  text-decoration: underline;
}

.paperless__pendingEmailUpdate {
  margin-bottom: 32px;
  text-align: right;
  color: #5b564d !important;
}                        

.paperless__dt_pendingEmailUpdate{
  margin-top: 30px;
  margin-bottom: 32px;
}

/* Small Screen */
@media (min-width: 0px) { 
  .paperless__div_email {
    display: block;
  }
  .paperless__dt_pendingEmailUpdate{
    display: block;
  }
  .paperless__email {
    margin-bottom: 12px;
  }
}

/* Medium Screen */
@media (min-width: 700px) {
  .paperless__div_email {
    display: flex;
    justify-content: space-between;
  }
  .paperless__dt_pendingEmailUpdate{
    display: flex;
    justify-content: space-between;
  }
  .paperless__email {
    margin-bottom: 32px;
  }
}