.alf-search-span {
    display: flex;
    height: 38px;
    border: 1px solid;
    border-radius: 50px;
  }
  .alf-search-div {
    display: flex;
    align-items: center;
    margin: 10px;
    width: 13px;
  }
  .alf-search-input {
    width: 100%;
    border-end-end-radius: 50px;
    border-start-end-radius: 50px;
    border: none;
    outline: none
  }