.alf-stepper {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.alf-stepper.horizontal {
  flex-direction: row;
  padding: 24px 0px;
}
.alf-stepper.vertical {
  flex-direction: column;
}
.alf-step.horizontal {
  flex-basis: 100%;
  padding-right: 8px;
}
.step-label {
  display: flex;
  align-items: center;
}
.step-label {
  display: flex;
  align-items: center;
}
.step-icon-container {
  display: flex;
  flex-shrink: 0;
  padding-right: 8px;
}
.step-label.active::after {
  content: '';
  background-color: #34d399;
  height: 1px;
  width: 100%;
  margin-left: 8px;
}
.step-label.inactive::after {
  content: '';
  background-color: #6648dc;
  height: 1px;
  width: 100%;
  margin-left: 8px;
}
.step-label-title {
  white-space: nowrap;
}

.step-content {
  margin-top: 8px;
  margin-left: 12px;
  padding-left: 20px;
  padding-right: 8px;
  margin-bottom: 8px;
}
.alf-step-separator {
  flex: 1 1 auto;
}
.alf-step-separator.vertical {
  padding: 0 0 8px;
  margin-left: 12px;
}
.alf-step-separator.horizontal {
  margin-top: 14px;
}

.alf-step-line {
  display: block;
  border-color: #bdbdbd;
}
.alf-step-line.horizontal {
  width: 100%;
  border-top-style: solid;
  border-top-width: 1px;
}
.alf-step-line.vertical {
  min-height: 24px;
  border-left-style: solid;
  border-left-width: 1px;
}
