
.user-profile-policy:not(:last-child) { 

 border-bottom: 1pt solid #B5B5B5;
}
.user-profile-policy { 

 padding: 16px;
 cursor: pointer;
 
}
   .user-profile {
  
   min-width:0;
  }
@media (min-width: 768px) {
 
   .user-profile {
  
   min-width:500px;
  }
}
@media (min-width: 1280px) {
 
   .user-profile {
  
   min-width:600px;
  }
}