.alf-dialog {
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99999;
  transition: all 0.15s linear;
}
.alf-dialog:focus {
  background-color: red;
}

.alf-dialog > .backdrop {
  z-index: -1;
  position: fixed;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  touch-action: none;
}

.alf-dialog > .wrapper {
  border-radius: 4px;
  padding: 24px;
  min-width: 350px;
  background: #fff;
  position: relative;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
}

.alf-dialog.fullscreen > .wrapper {
  width: 100%;
  margin: 0;
  max-width: 100%;
  max-height: none;
  border-radius: 0;
}

.alf-dialog .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alf-dialog .content {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.alf-dialog .actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 23px;
}
