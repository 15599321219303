.alf-select-policy {
  position: relative;
}
.alf-select-policy > .select-text {
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #6647dc;
  font-size: 16px;
  font-size: min(16rem, calc(16px * 1.5));
  font-weight: 600;
  line-height: 1.5;
  outline: none;
  margin-left: -16px;
  padding: 0 16px;
  font-family: 'proxima-nova', 'Helvetica', 'Arial', 'sans-serif';
  word-break: break-word;
}
.alf-select-policy > .select-text:focus {
  box-shadow: 0px 0px 6px 1px #6647dc;
  border-radius: 23px;
}
.alf-select-policy > .popover {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  max-width: 540px;
  background-color: #ffffff;
  border: 1px solid #2e2a3a;
  border-radius: 4px;
  top: 40px;
  left: 0;
  z-index: 3;
}

.alf-select-policy > .popover > .arrow:after,
.alf-select-policy > .popover > .arrow:before {
  bottom: 100%;
  left: 160px;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.alf-select-policy > .popover > .arrow:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #fff;
  border-width: 14px;
  margin-left: -14px;
}
.alf-select-policy > .popover > .arrow:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #5b564d;
  border-width: 15px;
  margin-left: -15px;
}

.alf-select-policy .popover .row {
  width: 100%;
  display: inline-block;
  padding: 10px 16px;
  cursor: pointer;
  border-radius: 4px;
}

.alf-select-policy .popover .row.selected {
  background-color: #ffffff;
  border: 2px solid #6648dc;
}
.alf-select-policy .popover .row.selected:focus,
.alf-select-policy .popover .row.selected:hover,
.alf-select-policy .popover .row:focus,
.alf-select-policy .popover .row:hover {
  background-color: #e0e7ff;
}

.alf-select-policy .popover .row .select-policy-row-desc {
  font-size: 12px;
  font-size: min(12rem, calc(12px * 1.5));
}
