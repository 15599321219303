.claim-details-small {
  width: 100%;
}

.claim-details-child {
  width: 90%;
  margin-left: 20px;
}

.claim-details-title {
  margin-right: 20px;
  font-size: 13px;
  font-weight: 800;
}

.claim-details-big {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
