/* Small Screen */
@media (min-width: 0px) {
  .panel {
    text-align: center;
  }
  .item-icon {
    margin-top: 40px;
    margin-right: 0px;
  }
  .second-row {
    margin-bottom: 50px;
  }
}

/* Medium Screen */
@media (min-width: 700px) {
  .panel {
    text-align: center;
  }
  .item-icon {
    margin-top: 10px;
    margin-right: 0px;
  }
  .first-row {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .second-row-text {
    text-align: left;
    margin-left: 29px;
  }
}

/* Large Screen */
@media (min-width: 1280px) {
  .panel {
    text-align: left;
    height: 440px;
    display: grid;
    align-content: space-between;
  }
  .item-icon {
    margin-top: 0px;
    margin-right: 64px;
  }
  .item-subtitle {
    margin-bottom: 20px !important;
  }
  .first-row {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .second-row {
    margin-bottom: 0px;
  }
  .second-row-text {
    margin-left: 0px !important;
  }
}

.item-subtitle {
  color: #6647dc !important;
}
.item-paragraph {
  color: #6647dc !important;
}
.button {
  width: 177px;
  margin-top: 20px;
  font-size: 16px;
}
