.policy-view > .header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 47px;
}

.policy-view .header .add-policy {
  margin-top: 20px;
}

.policy-view .alf-card {
  margin-bottom: 30px;
}

@media (min-width: 576px) {
  .policy-view .alf-card {
    margin-bottom: 18px;
  }
}

@media (min-width: 768px) {
  .policy-view .searchView {
    width: 30%;
    position: absolute;
    right: 48px;
  }
}

@media (max-width: 768px) {
  .policy-view .searchView {
    width: 50%;
  }
}

@media (max-width: 1279px) {
  .policy-view .billing-buttons {
    margin-top: 32px;
  }
}

@media (min-width: 1920px) {
  .policy-view .billing-separator {
    border-right: 1px solid #707070;
  }
  .policy-view .billing-cancellation {
    display: none;
  }
}

.policy-view .billing-content {
  margin-top: 32px;
}

@media (min-width: 1280px) {
  .policy-view .box-content {
    display: flex;
  }
  .policy-view .header {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .policy-view .header .add-policy {
    margin: 0;
  }
  .policy-view .alf-card {
    margin-bottom: 16px;
  }
  .policy-view .searchView {
    min-width: 20%;
    position: absolute;
    right: 260px;
  }
}
