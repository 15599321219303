.claim-information {
  margin-bottom: 10px;
  display: flex;
}

.claim-information .title {
  font-size: 15px;
}

.claim-information .value {
  font-size: 15px;
  font-weight: 800;
}

.claim-information .row {
  margin-bottom: 10px;
}
.claim-information .link {
  color: #6647dc;
  text-decoration: none;
  font-size: 15px;
  font-weight: 800;
}

@media only screen and (max-width: 1280px) {
  .claim-information {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .alf-panel > .summary {
    display: block;
    padding-right: 0px;
  }
}
