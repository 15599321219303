.alf-claims-root {
  flex-grow: 1;
  background-color: transparent;
}
.alf-claims-root .alf-claims-title {
  display: flex;
  justify-content: space-between;
}
.alf-claims-root .alf-claims-title .report-loss-button {
  width: 155px;
  margin-right: 16px;
}

@media (min-width: 1280px) {
  .withoutClaims {
    display: flex;
    align-items: center;
  }
  .withoutClaims .submit-button {
    margin-left: 16px;
  }
}
@media (max-width: 1199px) {
  .withoutClaims .submit-button {
    margin-top: 16px;
  }
}
@media (max-width: 768px) {
  .alf-claims-root .alf-claims-title {
    display: block;
  }
  .alf-claims-root .alf-claims-title .alf-claims-title-button {
    text-align: center;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .alf-claims-root .alf-claims-title .report-loss-button {
    margin-right: -12px;
  }
}

.claim-details.alf-panel {
  box-shadow: none;
}
.claim-details.alf-panel:not(:last-child) {
  border-bottom: 1pt solid #b5b5b5;
}
.claim-details.alf-panel::before {
  display: none;
}

.claim-details.alf-panel > .summary {
  padding-left: 0px;
  margin-bottom: -1px;
  min-height: 56px;
}

.claim-details.alf-panel.expanded > .summary > .content {
  margin: 12px 0;
}

.claim-details.alf-panel .details {
  padding: 0;
}
