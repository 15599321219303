.alf-pagination {
  width: 100%;
  padding: 10px 10px 10px 0;
}
.alf-pagination > ul {
  display: flex;
  list-style: none;
  justify-content: flex-end;
  width: 100%;
}
.alf-pagination > ul > li.current > button {
  background-color: #6648dc;
  color: #fff;
}
.alf-pagination .alf-button {
  border-radius: 0;
}
