.alf-link {
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5;
  margin: 0;
  padding: 0;
  font-family: 'proxima-nova', 'Helvetica', 'Arial', 'sans-serif';
  word-wrap: break-word;
}

.primary {
  color: #6647dc;
  text-decoration: none;
}
.alf-skip-link {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 999999;
  background-color: #6647dc;
  color: #fff;
  border: 1px solid #6647dc;
  box-shadow: 0px 5px 12px 5px rgba(0, 0, 0, 0.15);
  min-width: 100px;
  padding: 8px 20px !important;
  border: 0;
  outline: 0;
  border-radius: 23px;
  font-family: 'proxima-nova', 'Helvetica', 'Arial', 'sans-serif';
  font-size: 20px;
  font-size: min(20rem, calc(20px * 1.5));
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s linear;
  line-height: 1.2;
}

.alf-skip-link:not(:focus) {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}
.alf-skip-link:focus {
  background: #fff;
  color: #6647dc;
  border: 1px solid #6647dc;
  text-decoration: none;
  outline: 0;
}
