.alf-list {
  margin-top: 24px;
}

.alf-list > .row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}

.alf-list .row > .list-label {
  flex-basis: calc(calc(500px - 100%) * 999);
}

.alf-list > .row > span:last-child {
  min-width: 85px;
}
.alf-list > .row > .divider {
  flex-grow: 1;
  padding-left: 32px;
  min-height: 16px;
  background-image: radial-gradient(circle, #5b564d 0, rgba(0, 0, 0, 0) 2px);
  background-position: center;
  background-size: 9px;
  background-repeat: repeat-x;
}

@media (min-width: 768px) {
  .alf-list {
    margin-top: 0px;
  }
}
