.alf-button {
  padding: 0 20px;
  border: 0;
  border-radius: 23px;
  font-family: 'proxima-nova', 'Helvetica', 'Arial', 'sans-serif';
  font-size: 16px;
  font-size: min(16rem, calc(16px * 1.5));
  font-weight: bold;
  outline: 0;
  position: relative;
  white-space: nowrap;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s linear;
  user-select: none;
}

a.alf-button.lg {
  padding: 10px 20px 0;
  height: 46px;
}

a.alf-button.md {
  padding: 8px 20px 0;
  height: 40px;
}

a.alf-button.sm {
  padding: 6px 20px 0;
  height: 36px;
}

a.alf-button.xs {
  padding: 4px 20px 0;
  height: 32px;
}

.alf-button.lg {
  height: 46px;
}

.alf-button.md {
  height: 40px;
}

.alf-button.sm {
  height: 36px;
}

.alf-button.xs {
  height: 32px;
}

.alf-button > span {
  display: inline-block;
  pointer-events: none;
}
.alf-button > i {
  margin-right: 8px;
}
.alf-button.fullwidth {
  width: 100% !important;
}

.alf-button.default {
  background-color: #fff;
  color: #6648dc;
  border: 3px solid #6648dc;
}
.alf-button.default:hover,
.alf-button.default:focus {
  background: #483499;
  border: 3px solid #483499;
  color: #fff;
  text-decoration: underline;
  outline: 0;
}
.alf-button.default:active {
  background-color: #e8e8e8;
  color: #483499;
}

.alf-button.primary {
  background-color: #6648dc;
  color: #fff;
  border: 3px solid #6648dc;
}
.alf-button.primary:hover,
.alf-button.primary:focus {
  background: #483499;
  color: #fff;
  border: 3px solid #483499;
  text-decoration: none;
  outline: 0;
}

.alf-button.primary:active {
  background-color: #483499;
  color: #fff;
}
.alf-button.primary:hover > .alf-icon {
  fill: #fff !important;
}
.alf-button.primary:hover > .alf-spinner {
  fill: #fff !important;
}
.alf-button.secondary {
  background-color: #2b1a6c;
  color: #e8e4fa;
}
.alf-button.secondary.white-border {
  border: 2px solid white;
}
.alf-button.secondary:hover,
.alf-button.secondary:focus {
  color: #483499;
  background: #e8e4fa;
  outline: 0;
  border: 1px solid #483499;
}
.alf-button.secondary:active {
  background-color: #c6dad9;
  color: #483499;
}
.alf-button.secondary:hover > .alf-icon {
  fill: #fff !important;
}
.alf-button.secondary:hover > .alf-spinner {
  fill: #fff !important;
}
.alf-button-icon {
  background-color: #483499;
  color: #fff;
  padding: 0px;
  display: flex;
  background-color: transparent;
  border-radius: 50%;
  border: 0;
}
.alf-button-icon:hover {
  cursor: pointer;
}
.alf-button-icon:focus {
  cursor: pointer;
  background: #fff;
  color: #483499;
  box-shadow: 0px 0px 6px 1px #483499;
  outline: none;
}
.alf-button-icon:disabled {
  box-shadow: none;
  cursor: not-allowed;
}
.alf-button-icon:active {
  color: #483499;
  background-color: #fafafa;
}

.alf-button[disabled]:not(.loading) {
  background: #dbdbdb;
  cursor: not-allowed;
  color: #333;
  text-shadow: none;
  box-shadow: none;
  border: none;
}

@media only screen and (max-width: 768px) {
  .alf-button {
    font-size: 16px;
    font-size: min(16rem, calc(16px * 1.5));
  }
}
