.discount__button {
  width: 115px;
}

.discount__title {
  color: #483498 !important;
  margin-bottom: 20px;
  text-align: center !important;
}

.discount__children {
  background-color: #F5F7F9;
  padding: 15px;
}

.discount__subtitle {
  margin-bottom: 10px
}

.discount__paragraph {
  text-align: justify
}
