.alf-spinner {
  text-align: center;
  display: inline-block;
  vertical-align: top;
}

.alf-spinner.default,
.alf-spinner.primary,
.alf-spinner.secondary {
  fill: #fff;
}
