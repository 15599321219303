@media (min-width: 1280px) {
  .hr {
    position: absolute;
    height: 390px;
    width: 0px;
    border: 1px solid #6648dc;
    display: block !important;
  }
  .panelRight {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media (min-width: 900px) {
  .col-hr {
    padding: 0% 1% !important;
  }
}
@media (min-width: 768px) {
  .col-hr {
    width: 544px;
    margin-top: 25px;
    padding: 0% 8%;
  }
  .hr {
    border: 1px solid #6648dc;
    display: block !important;
  }
  .panelRight {
    padding-left: 4%;
    padding-right: 4%;
    display: flex;
    align-items: center;
  }
  .panelLeft {
    padding-left: 4%;
    padding-right: 4%;
  }
}

.hr {
  display: none;
}
