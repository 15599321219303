.alf-input {
  box-sizing: border-box;
  margin: 0;
  position: relative;
  display: inline-block;
  height: 36px;
  padding: 8px 8px;
  font-size: 16px;
  font-size: min(16rem, calc(16px * 1.5));
  font-family: 'proxima-nova', 'Helvetica', 'Arial', 'sans-serif';
  border: 1px solid #5b564d;
  transition: all 0.3s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.alf-input::placeholder {
  color: #acaaa5;
}
.alf-input:-ms-input-placeholder {
  color: #acaaa5;
}
.alf-input::-webkit-input-placeholder {
  color: #acaaa5;
}

.alf-input:focus {
  border-color: #5d79bd;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.alf-input.no-focus:focus {
  border: 1px solid #5b564d;
  outline: none;
  box-shadow: none;
}
.alf-input.fullWidth {
  width: 100%;
}

.alf-input.error:focus {
  border-color: #dc2a2a;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.alf-input.error.no-focus:focus {
  border: 1px solid #dc2a2a;
  outline: none;
  box-shadow: none;
}
.alf-input.success {
  border: 1px solid #1e824c;
}
.alf-input.success:focus {
  border-color: #1e824c;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px #8fd8ae;
}
.alf-input.success.no-focus:focus {
  border: 1px solid #1e824c;
  outline: none;
  box-shadow: none;
}
.alf-input[disabled],
.alf-input[disabled]:hover {
  cursor: not-allowed;
  color: #333333;
  background-color: #dbdbdb;
  text-shadow: none;
  box-shadow: none;
  border-color: #dbdbdb;
}
.alf-input.error {
  border: 1px solid #dc2a2a;
}

.alf-textarea {
  box-sizing: border-box;
  margin: 0;
  position: relative;
  display: inline-block;
  font-family: 'proxima-nova', 'Helvetica', 'Arial', 'sans-serif';
  padding: 8px 8px;
  font-size: 16px;
  font-size: min(16rem, calc(16px * 1.5));
  border: 1px solid #5b564d;
  transition: all 0.3s;
}
.alf-textarea:focus {
  border-color: #5d79bd;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.alf-textarea.no-focus:focus {
  border: 1px solid #5b564d;
  outline: none;
  box-shadow: none;
}
.alf-textarea.fullWidth {
  width: 100%;
}

.alf-textarea.error {
  border: 1px solid #dc2a2a;
}
.alf-textarea.error:focus {
  border-color: #dc2a2a;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.alf-textarea.error.no-focus:focus {
  border: 1px solid #dc2a2a;
  outline: none;
  box-shadow: none;
}
.alf-textarea.success {
  border: 1px solid #1e824c;
}
.alf-textarea.success:focus {
  border-color: #1e824c;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px #8fd8ae;
}
.alf-textarea.success.no-focus:focus {
  border: 1px solid #1e824c;
  outline: none;
  box-shadow: none;
}
.alf-textarea[disabled],
.alf-textarea[disabled]:hover {
  cursor: not-allowed;
  color: #333333;
  background-color: #dbdbdb;
  text-shadow: none;
  box-shadow: none;
  border-color: #dbdbdb;
}
