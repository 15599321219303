.alf-card {
  min-width: 175px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  background-color: #fff;
  padding: 16px 20px;
  border-radius: 4px;
  margin-bottom: 24px;
}

.alf-card:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
}

@media only screen and (min-width: 550px) {
  .alf-card {
    padding: 24px 48px;
  }
}
